<template>
  <div class="social-networks">
    <ul class="social-networks__list">
      <li>
        <a class="social-networks__link" href="https://vk.com/snedson"
          ><img class="social-networks__icon" src="@/assets/vk-logo.png"
        /></a>
      </li>
      <li>
        <a class="social-networks__link" href="https://t.me/snedson"
          ><img class="social-networks__icon" src="@/assets/tg-logo.png"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.social-networks {
  &__list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__icon {
    width: 35px;
    height: 35px;
    filter: black; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */

    &:hover {
      filter: none;
      -webkit-filter: grayscale(0);
    }
  }
}

@media (max-width: 1180px) {
  .social-networks {
    &__icon {
      filter: none;
      -webkit-filter: grayscale(0);
    }
  }
}
</style>
